import { getCookie } from "cookies-next";
import { decodeJWT } from 'aws-amplify/auth';

export const getTokenFromCookie = (userPoolId: string) => {
    const lastAuthUserCookieName = `CognitoIdentityServiceProvider.${userPoolId}.LastAuthUser`;
    const lastAuthUserCookie = getCookie(lastAuthUserCookieName);

    if (!lastAuthUserCookie) return null

    const getAmplifyAuthKeys = (name: string, lastAuthUser: string) =>
        [name]
            .map(
                (key) =>
                    `CognitoIdentityServiceProvider.${userPoolId}.${lastAuthUser}.${key}`
            )
            .concat(lastAuthUserCookieName)?.reduce(
            (acc, key) => ({
                ...acc,
                [key]: getCookie(key),
            }),
            {}
        );

    const idTokenKey = getAmplifyAuthKeys('idToken', lastAuthUserCookie);
    const accessTokenKey = getAmplifyAuthKeys('accessToken', lastAuthUserCookie);

    const amplifyAuthKeys = {
        ...idTokenKey,
        ...accessTokenKey
    };

    return {
        lastAuthUser: lastAuthUserCookie,
        // @ts-ignore
        idToken: amplifyAuthKeys?.[`CognitoIdentityServiceProvider.${userPoolId}.${lastAuthUserCookie}.idToken`] ? decodeJWT(amplifyAuthKeys?.[`CognitoIdentityServiceProvider.${userPoolId}.${lastAuthUserCookie}.idToken`])?.payload : null,
        // @ts-ignore
        rawAccessToken: amplifyAuthKeys?.[`CognitoIdentityServiceProvider.${userPoolId}.${lastAuthUserCookie}.accessToken`],
    }
}

export const checkIfUserIsAuthenticated = (userPoolId: string) => {
    const token = getTokenFromCookie(userPoolId);
    // @ts-ignore
    return token?.idToken !== null && token?.idToken !== undefined && token?.idToken?.exp > Date.now() / 1000;
}